.section-01, .section-05 {
    background-color: #ededeb;
}
.logo_tg {
    padding-left: 50px;
    text-transform: none;
}
.about-plitka-li-c {
    vertical-align: middle;
    height: 170px;
    padding-top: 0;
}
.about-plitka-title span {
    font-weight: bold;
}
.tile_item,.about-about table td p {
    font-family: Subaru-Light, sans-serif;
    text-transform: uppercase;
}
#index_time .sp-cont-left {
    .blog_date {
        margin-bottom: 1em;
    }
    .exeption_new {
        margin-top: 1em;
        a {
            color: #d8a787;
            text-transform: none;
            font-family: "Subaru-Regular", sans-serif;
        }
    }
}

.eg-load-more-block.eg-load-more-layout-3 a.eg-load-more-trigger {
    background: #e4661b;
    border:none;
    border-radius: 0;
    box-shadow: none!important;
}
